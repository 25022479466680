import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Input from '../elements/Input';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplitDevs extends React.Component {

  componentDidMount() {
    // this is only to handle inline style on window resize
    window.onresize = function () {
      this.forceUpdate();
    }.bind(this);
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );


    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item" style={inlineStyle()}>
                <div className="hero-content split-item-content center-content-mobile">
                  <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="150">
                    Join and have remote first companies apply to you.
                  </h1>
                
                  <div className="reveal-from-bottom" data-reveal-delay="450">
                  <form name="contact_devs" data-netlify="true" method="post">
                  <input  type="hidden" name='form-name' value="contact" />
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          label="Full name"
                          name="subject"
                          placeholder="Full name" 
                          labelHidden
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label="Email"
                          name="email"
                          placeholder="Company Email"
                          labelHidden
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          label="Github"
                          name="github username"
                          placeholder="Github user profile link"
                          labelHidden
                          optional />
                      </div>
                      <div className="mb-12">
                        <Input
                          label="LinkedIn"
                          name="LinkedIN profile"
                          placeholder="LinkedIn Profile Link"
                          labelHidden
                          optional />
                      </div>
    
                    <Button type="submit" color="primary" wideMobile>
                      Sign Up
                    </Button>
                    
                    </fieldset>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>
    );
  }
}

// inline style
const inlineCss = {
  minHeight: '492px'
}

const inlineStyle = function () {
  if (window.innerWidth > 641) {
    return inlineCss;
  }
};

HeroSplitDevs.propTypes = propTypes;
HeroSplitDevs.defaultProps = defaultProps;

export default HeroSplitDevs;