import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Team extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'We\'re fully dedicated to finding engineers fully-remote roles at amazing US companies.',
      paragraph: 'Here are some success stories from other engineers that have entered the Remot platform and were offered positions at great US companies.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/miguel_chirinos.jpeg')}
                        alt="Team member 01"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Miguel Chirinos
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Software Engineer at Vial
                    </div>
                    <p className="m-0 text-sm">
                      Miguel is building out Vial's clinical trials repository system and supporting APIs which allowed us to take on more customers and track trials programmatically which wasn’t possible before.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/team-member-01.jpg')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Dennis McKindley
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Software Engineer at InterviewQuery
                    </div>
                    <p className="m-0 text-sm">
                      Dennis is helping data scientists get jobs on InterviewQuery by implementing their core Data Science testing suite.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/meena.jpeg')}
                        alt="Team member 05"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Meena Sundinti
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Data Engineer at Slower.ai
                    </div>
                    <p className="m-0 text-sm">
                      Meena was able to secure a position as a Data Engineer at Slower Inc where she'll be working with bleeding edge data architecture tooling across GCP and Confluent infra.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/paley.png')}
                        alt="Team member 05"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      David P.
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Software Engineer at ClimateClub
                    </div>
                    <p className="m-0 text-sm">
                      David was hired to ClimateClub as a Front-end Senior Software Engineer to help them scale their SaaS B2B Climate awareness platform.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/team-member-04.jpg')}
                        alt="Team member 03"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Lautaro A.
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Software Engineer at Vial
                    </div>
                    <p className="m-0 text-sm">
                      Lautaro is helping Vial create an automated clinical trials product which will change the way clinical trial tests are performed in the future.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/team-member-05.jpg')}
                        alt="Team member 04"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                     Peter Gustafson
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Sr. Technical Writer at Slower.ai
                    </div>
                    <p className="m-0 text-sm">
                      Peter was able to land a fully-remote job at Slower.ai.  He's created backend data architectural design documents and API references used by Lyft, Riot Games, Confluent among others.
                    </p>
                  </div>
                </div>
              </div>

         

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/team-member-03.jpg')}
                        alt="Team member 06"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Wells C.
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Data Engineer at Slower.ai
                    </div>
                    <p className="m-0 text-sm">
                      Wells is working on very large architectural challenges on Confluent and Google Platform tooling including Apache Airflow, Anthos and GKE.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;