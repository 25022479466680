import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
//import Pricing from '../components/sections/Pricing';
import Team from '../components/sections/Team';
import HeroSplitDevs from '../components/sections/HeroSplitDevs';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
//import Cta from '../components/sections/Cta';
// import some required elements
//import Image from '../components/elements/Image';
//import Input from '../components/elements/Input';
//import ButtonGroup from '../components/elements/ButtonGroup';
//import Button from '../components/elements/Button';
//import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    // const genericSection01Header = {
    //   title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    // } 

    // const genericSection02Header = {
    //   title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    // }

    // const genericSection03Header = {
    //   title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    // }   

    const genericSection04Header = {
      title: 'FAQ'
    }

    return (
      <React.Fragment>
        {/* <Pricing pricingSwitcher className="illustration-section-02" /> */}
        <HeroSplitDevs className="illustration-section-01" />
        <Team topDivider />
   
        <Roadmap topDivider />
        <FeaturesSplit invertMobile topDivider imageFill />
{/* 
        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Lorem ipsum is placeholder text commonly used in the graphic.</h2>
            <p>
              Lorem ipsum dolor sit amet, <a href="#0">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/image-placeholder.svg')}
                alt="Placeholder"
                width={712}
                height={400} />
              <figcaption className="text-color-low">A super-nice image <span role="img" aria-label="mile">😀</span></figcaption>
            </figure>
            <h4>Flexibility</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
              <li>Lorem ipsum dolor sit amet, consectetur.</li>
            </ul>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.
            </p>
          </div>
        </GenericSection> */}
{/* 
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <div className="center-content">
              <Button
                color="primary"
                aria-controls="demo-modal"
                onClick={this.openModal}>Open modal</Button>
            </div>
            <Modal
              id="demo-modal"
              show={this.state.demoModalActive}
              handleClose={this.closeModal}
            >
              <div className="center-content">
                <h3 className="mt-16 mb-8">Join our newsletter</h3>
                <p className="text-sm">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
                </p>
              </div>
              <form style={modalFormStyle}>
                <div className="mb-12">
                  <Input
                    type="email"
                    label="This is a label"
                    placeholder="Your best email"
                    labelHidden />
                </div>
                <Button color="primary" wide>Subscribe</Button>
              </form>
              <div className="center-content mt-24">
                <a
                  className="text-xxs fw-500 tt-u"
                  aria-label="close"
                  href="#0"
                  onClick={this.closeModal}
                >No thanks!</a>
              </div>
            </Modal>
          </div >
        </GenericSection>         

        <GenericSection topDivider className="center-content">
          <div className="container">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <ButtonGroup>
              <Button color="primary" wideMobile>Get started now</Button>
              <Button color="secondary" wideMobile>Get started now</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button color="dark" wideMobile>Get started now</Button>
              <Button wideMobile>Get started now</Button>
            </ButtonGroup>
          </div>
        </GenericSection> */}
{/* 
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection03Header} className="center-content" />
            <form style={formStyle}>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden>
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  value="hello@cruip.com">
                  <Button color="primary" loading>Early access</Button>
                </Input>
              </div>              
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  status="error"
                  hint="Something is wrong.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email"
                  formGroup="desktop"
                  labelHidden
                  status="success"
                  hint="You've done it.">
                  <Button color="primary">Early access</Button>
                </Input>
              </div>
            </form>
          </div>
        </GenericSection>        */}

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="Will I get to choose what companies I interview with?" active>
                Yes, after you've received a request by a company to interview you, you can accept or deny their request.
              </AccordionItem>
              <AccordionItem title="What types of companies will I contacted by?">
                You'll be contacted by all well funded startup companies from varying industries, usually in the small-medium size (1-200 employees)
              </AccordionItem>
              <AccordionItem title="Will Remot provide technical assessment challenges?">
                Where necessary, Remot may present a completed technical challenge to employers to make you more marketable and to get you more interviews.
              </AccordionItem>
              <AccordionItem title="How long does it take for me to start?">
                Once you've entered your skills, github or other profile information the turnaround time to be contacted by companies is typically one week.
              </AccordionItem>
              <AccordionItem title="Can I create a profile if I'm not a developer?">
                Yes, we allow for those with different skills like technical writing, UI/UX designer and product managers to join our platform.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>        

        
        {/* <Cta split /> */}
      </React.Fragment>
    );
  }
}

// inline style
// const formStyle = {
//   maxWidth: '420px',
//   margin: '0 auto'
// }

// const modalFormStyle = {
//   maxWidth: '320px',
//   margin: '0 auto'
// }

export default Secondary;