import React from 'react';
import SectionHeader from '../components/sections/partials/SectionHeader';
import ClientSuccessTeam from '../components/sections/ClientSuccessTeam';
import GenericSection from '../components/sections/GenericSection';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class AboutUs extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    const genericSection04Header = {
      title: 'FAQ'
    }

    return (
      <React.Fragment>
        {/* { <Pricing pricingSwitcher className="illustration-section-02" /> } */}
        <ClientSuccessTeam topDivider />
        
{

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="What is the typical time to hire at Remot?" active>
                We've filled positions in as little as two weeks but we've seen the median time as around 4-6 weeks.
              </AccordionItem>
              <AccordionItem title="Will Remot provide technical assessment challenges?">
                Yes. Remot provides technical assessments to offload the technical phone screening work that companies deal with.  We've has much faster times to hire and lower time investment from our clients to find the best fit.
              </AccordionItem>
              <AccordionItem title="How long does it take to start?">
                Once our contract agreement is signed we can start sending you canidates within 24-72 hours.  For clients who opt in for our technical step it's about a week. 
              </AccordionItem>
              <AccordionItem title="What types of companies do you partner with?">
                We can handle early, mid and large scale companies.  We typically shoot for 30+ head count companies.
              </AccordionItem>
            </Accordion>
          </div>
        </GenericSection>        
  }
        
      </React.Fragment>

    );
  }
}


export default AboutUs;