import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Client Stories',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Rob helped us hire one of our recent cloud engineer roles and specializes in hiring folks out of South America - 30% the cost of silicon valley rates for the top 5% of hires. I was introduced to him through Alex Sieira, who you and I both know through the Cloud Security Forum. I've really enjoyed working with him so far - great with feedback, and his process takes all the grunt work out of the hiring process so I can focus on building.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Kinnaird McQuade, CTO</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="/">NightVision Security</a>
                    </span>
                  </div>
                </div>
              </div>
           
              <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Remot helped us hire our first engineer who has extensive experience leading development teams.  So far he has built out the InterviewQuery core codebase, job board and candidate assessment product which increased engagement on the platform resulting in increased revenue within 6 months.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Jay Feng, CEO</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://interviewquery.com">InterviewQuery</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      With the internal recruitment team that Remot has staffed for us, we're on track to hire 20 engineers before the end of the year.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Rikin Shah, CEO</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://slower.ai">Slower</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Remot helped us hire two full-stack engineers who built out the clinical trials database and supporting APIs which allowed us to take on more customers and track trials programmatically which wasn’t possible before.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Tina Jiang, PM</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://Vial.com">Vial</a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Remot was able to assist us in hiring remote engineers in Latin America.  If you're looking for hire remote devs from Latam, Remot will be able to help you. </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Alan Leard, CTO</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="https://climateclub.com">Climate Club</a>
                    </span>
                  </div>
                </div>
              </div>

      

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;