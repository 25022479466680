import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Roadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Our Process',
      paragraph: 'Our team has created an easy 5 step process to help you secure a fully-remote position at well funded startups'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Step 1">
                Sign up for a Remot account and your skills and preferences are saved for other companies to see.
              </TimelineItem>
              <TimelineItem title="Step 2">
                Employers will be presented with your skills, desired salary and personal preferences.
              </TimelineItem>
              <TimelineItem title="Step 3">
                The company will schedule a time to meet with you based on your schedule, automatically.
              </TimelineItem>
              <TimelineItem title="Step 4">
                After passing through the interview process with the company they will present you with an offer.
              </TimelineItem>
              <TimelineItem title="Step 5">
                After accepting your offer you'll be set up with an onboarding resource for your new job.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;