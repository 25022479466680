import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class ClientSuccessTeam extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'We\'re in the PEOPLE business',
      paragraph: 'Here is our dream recruitment team at Remot.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/meonphone.png')}
                        alt="Team member 01"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Robert Griesmeyer
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      CEO
                    </div>
                    <p className="m-0 text-sm">
                      Robert Griesmeyer has over a decade of experience in ML/AI as an engineer and has worked as a technical leader at Flipboard, Zite, Jobr, Monster and Vium.  Robert was the Head of Engineering at Proper where he aided in hiring a large team of engineers to build out the next generation of Accounting Automation software.  Currently he's working to lead change in the recruitment space.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/hamid.png')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Hamid Wahab
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Head Of Talent
                    </div>
                    <p className="m-0 text-sm">
                    With near a decade of talent acquisition experience working for FAANG companies, Hamid is now leading the charge at Remot in acquiring talent in novel ways. He has built multiple organizations from scratch in many sectors i.e. engineering, life science, medical. Hamid has proven track record of solving hiring problems and emphasizes the importance of Diversity, Equity and Inclusion when attaining talent. He focuses on screening and candidate preparation to maximize hiring results so companies can scale their teams quickly.
                    </p>
                  </div>
                </div>
              </div>
         
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/jeremias.jpeg')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Jeremias Vilagines
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Senior Recruiter
                    </div>
                    <p className="m-0 text-sm">
                    Jeremias is an HR professional 5+ years of working experience in different fields of Human Resources, more specifically in IT and Technical Recruitment for multinational companies in the US and Latam. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/nanda2.png')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                    Fernanda Minniti Mançano
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Senior Recruiter
                    </div>
                    <p className="m-0 text-sm">
                    Fernanda has 8 year experience in recruitment and has managed Talent Development, Consulting and Executive Education.  She has differentiated and personalized solutions that transform people and companies through her Grow Methodology.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/Deni.jpeg')}
                        alt="Team member 02"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Denev Esmeralda Labourdette Gutierrez
                    </h5>
                    <div className="team-item-role text-xxs fw-500 tt-u text-color-primary mb-8">
                      Senior Recruitment Expert
                    </div>
                    <p className="m-0 text-sm">
                    Denev Gutierrez has 14 years of experience in recruitment for technology positions in both Latam, the US and Canada.  She specializes in recruitment strategy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ClientSuccessTeam.propTypes = propTypes;
ClientSuccessTeam.defaultProps = defaultProps;

export default ClientSuccessTeam;