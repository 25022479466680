import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader'
import Timeline from '../elements/Timeline';
import TimelineItem from '../elements/TimelineItem';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class ClientRoadmap extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'roadmap section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'roadmap-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Our Novel Approach',
      paragraph: 'If you partner with us we\'ll save your company time and money while hiring top talent.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Step 1">
                Our recruitment team sources and identifies candidates with the best backgrounds to add to our backend pipeline.
              </TimelineItem>
              <TimelineItem title="Step 2">
                The candidates selected by the previous stage go through a screening interview with our recruiters where we align expectations and decide whether the candidate is right for the position. 
              </TimelineItem>
              <TimelineItem title="Step 3">
                Candidates schedule with our Remot engineering experts who meet with candidates for in-person 1-1 technical screenings based on the specific needs of the customer.
              </TimelineItem>
              <TimelineItem title="Step 4">
                Candidates who pass both previous stages will be referred to you.  You only end up being exposed to top 5% of the total pipeline of applicants.
              </TimelineItem>
              <TimelineItem title="Step 5">
                If the decision is to hire we help you negotiate the best deal for your company to acquire the talent.
              </TimelineItem>
              <TimelineItem title="Step 5">
                Once the candidate accepts the offer we immediately prepare the candidate for their start date.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

ClientRoadmap.propTypes = propTypes;
ClientRoadmap.defaultProps = defaultProps;

export default ClientRoadmap;